import React from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper/modules";

const App = () => (
    <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        loop={true}
    >
        <SwiperSlide>
            <img src="" alt="Car 1" />
        </SwiperSlide>
        <SwiperSlide>
            <img src="" alt="Car 2" />
        </SwiperSlide>
        <SwiperSlide>
            <img src="" alt="Car 3" />
        </SwiperSlide>
    </Swiper>
);

ReactDOM.render(<App />, document.getElementById("root"));
